package tta.destinigo.talktoastro.feature_expert_listing.feature_expert_notify

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.will_notify_icon

@Composable
fun NotifyScreen() {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
            .padding(vertical = 16.dp), // Equivalent to spaces at top and bottom
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Spacer(modifier = Modifier.height(30.dp)) // Equivalent to space3

        // Icon
        Box(
            modifier = Modifier
                .size(40.dp) // dp_45 equivalent
                .background(
                    color = Color.Transparent,
                    shape = CircleShape
                )
        ){
            Icon(painter = painterResource(Res.drawable.will_notify_icon), contentDescription = null, modifier = Modifier.fillMaxSize())
        }

        Spacer(modifier = Modifier.height(16.dp)) // Spacing between the icon and the first TextView

        // Title TextView
        Text(
            text = "You Will be Notified",
            //fontFamily = FontFamily(Font(R.font.opensans_bold)),
            fontSize = 16.sp,
            textAlign = TextAlign.Center,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 32.dp)
        )

        Spacer(modifier = Modifier.height(16.dp)) // Spacing between title and description

        // Description TextView
        Text(
            text = "You will be notified when an expert is available. You can also consult with another expert.",
            fontSize = 12.sp,
            textAlign = TextAlign.Center,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 32.dp)
        )

        Spacer(modifier = Modifier.height(30.dp)) // Equivalent to space4
    }
}
