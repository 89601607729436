package tta.destinigo.talktoastro.feature_expert_listing.expert_list_page.expert_list_state_info

import tta.destinigo.talktoastro.shared.data.RequestConsultant


sealed class ExpertListState {
    data object Loading : ExpertListState()
    data class Success(val consultants: List<RequestConsultant>, val isFreeApplicable:Boolean) : ExpertListState()
    data class Error(val message: String?) : ExpertListState()
}
