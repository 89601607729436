package tta.destinigo.talktoastro.feature_expert_listing.data.remote.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.shared.data.SubCategoriesModel

@Serializable
data class CategoriesResponse(
    @SerialName("data") val `data`: CategoryData,
    @SerialName("status") var status: Boolean = false,
    @SerialName("message") var message: String? = "Unexpected Error",
    @SerialName("code") var code: Int = 0
)

@Serializable
data class CategoryData(
    @SerialName("categories") val categories: List<Category>?
)

@Serializable
data class Category(
    @SerialName("id") val id: Int,
    @SerialName("name") val name: String,
    @SerialName("image") val image: String,
    @SerialName("image_url") val image_url: String,
    @SerialName("sub") val subcategories: List<SubCategoriesModel>?
)

//@Serializable
//class SubCategoriesModel(
//    @SerialName("name") var name: String,
//    @SerialName("isSelected") var isSelected: Boolean,
//    @SerialName("id") var id: Int
//)

