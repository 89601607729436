package tta.destinigo.talktoastro.feature_expert_listing.di


import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_expert_listing.data.remote.ExpertApi
import tta.destinigo.talktoastro.feature_expert_listing.data.remote.SearchExpertApi
import tta.destinigo.talktoastro.feature_expert_listing.domain.ExpertListRepo

val expertListCommonModule = module{
    single { ExpertApi() }
    single { SearchExpertApi() }
    single { ExpertListRepo(get()) }
}