package tta.destinigo.talktoastro.feature_expert_listing.expert_list_page.widget

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyRow
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.material3.FilterChip
import androidx.compose.material3.FilterChipDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.shared.data.SubCategoriesModel
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.Scale.VERTICAL_PADDING_SMALL
import tta.destinigo.talktoastro.shared.theme.primaryColor


//@Composable
//fun ChipGroup(
//    chipItems: List<SubCategoriesModel>,
//    onChipSelected: (String) -> Unit
//) {
//    val itemsWithAll = listOf(SubCategoriesModel(id = -1, name = "All")) + chipItems
//    var selectedChipIndex by remember { mutableStateOf(0) }
//    LazyRow(
//        modifier = Modifier.padding(horizontal = Scale.HORIZONTAL_PADDING, vertical = Scale.VERTICAL_PADDING_SMALL)
//    ) {
//        itemsIndexed(itemsWithAll) { index, chipText ->
//            Chip(
//                text = chipText.name,
//                onChipSelected = {
//                    selectedChipIndex = index
//                    onChipSelected(chipText.name)
//                },
//                isSelected = index == selectedChipIndex
//            )
//        }
//    }
//}
//
//
//
//@Composable
//fun Chip(
//    text: String,
//    onChipSelected: () -> Unit,
//    isSelected: Boolean
//) {
//    Box(
//        modifier = Modifier
//            .padding(end = 5.dp)
//            .clip(RoundedCornerShape(Scale.CORNER_SMALL))
//            .background(if (isSelected) primaryColor else Color.White)
//            .clickable(onClick = onChipSelected)
//            .padding(horizontal = Scale.HORIZONTAL_PADDING_SMALL, vertical = VERTICAL_PADDING_SMALL)
//    ) {
//        Row {
//            if (isSelected) {
//                Icon(
//                    imageVector = Icons.Default.Check,
//                    contentDescription = null,
//                    tint = Color.White,
//                    modifier = Modifier.padding(start = 4.dp)
//                )
//            }
//            Spacer(modifier = Modifier.width(5.dp))
//            Text(
//                text = text,
//                fontSize = 14.sp,
//                color = if (isSelected) Color.White else Color.Black
//            )
//        }
//    }
//}


@Composable
fun ChipGroup(
    chipItems: List<SubCategoriesModel>,
    onChipSelected: (String) -> Unit
) {
    val itemsWithAll = listOf(SubCategoriesModel(id = -1, name = "All")) + chipItems
    var selectedChipIndex by remember { mutableStateOf(0) }

    LazyRow(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp, vertical = 8.dp),
        horizontalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        itemsIndexed(itemsWithAll) { index, chipText ->
            FilterChip(
                selected = index == selectedChipIndex,
                onClick = {
                    selectedChipIndex = index
                    onChipSelected(chipText.name)
                },
                label = {
                    Text(text = chipText.name)
                },
                leadingIcon = if (index == selectedChipIndex) {
                    {
                        Icon(
                            imageVector = Icons.Default.Check,
                            contentDescription = null,
                            tint = Color.White
                        )
                    }
                } else null,
                shape = RoundedCornerShape(50),
                colors = FilterChipDefaults.filterChipColors(
                    selectedContainerColor = primaryColor,
                    selectedLabelColor = Color.White,
                    containerColor = MaterialTheme.colorScheme.surface,
                    labelColor = MaterialTheme.colorScheme.onSurface
                )
            )
        }
    }
}
